import "./LandingPage.css";
import React, { useEffect, useState, useRef } from "react";
import { Popup } from "../components/Popup/Popup";
import { Button } from "../components/Button/Button";
import mixpanel from "mixpanel-browser";
import { SERVER_URL } from "../constants";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: true,
});

// Add the bot detection code
const userAgentBotTest = navigator.userAgent;

if (/(Mozilla\/4.0)/i.test(userAgentBotTest)) {
  mixpanel.register({ $ignore: true });
}

const LandingPage = (props) => {
  const { userData } = props;
  const [popupOpen, setPopupState] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);

  const handleSubscribe = () => {
    mixpanel.track("Email submitted to join private beta");
    setPopupState(false);

    setShowThankYouPopup(true);
    setTimeout(() => {
      setShowThankYouPopup(false);
      window.location.reload();
    }, 4500);
  };

  useEffect(() => {
    mixpanel.track_pageview({ page: "Landing page" });
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const users = document.getElementsByClassName("users")[0].children;

  //     Array.from(users).forEach((elem) => {
  //       const rect = elem.getBoundingClientRect();

  //       if (rect.top + rect.height / 2 < window.innerHeight) {
  //         setTimeout(() => {
  //           elem.classList.add("shown");
  //         }, rect.left / 1.5);
  //       } else {
  //         elem.classList.remove("shown");
  //       }
  //     });
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div>
      <div className="header">
        <h1>YOUTIQUE</h1>

        <br></br>
        <br></br>
        <br></br>

        <p className="bigtext">
          Youtique helps you share and monetize links to your favorite products
          regardless of how big your social following is.
        </p>

        <br></br>
        <br></br>
        <br></br>

        <p className="subtext">We are currently in private beta.</p>
        <p className="subtext">
          <a
            onClick={() => {
              if (popupOpen === false) setPopupState(1);
              else setPopupState((popupOpen + 1) % 3);

              mixpanel.track("Clicked to join private beta");
            }}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            <strong>Sign up for our waitlist here</strong>
          </a>
        </p>
      </div>
      {/* <ul className="users">
        {userData
          .filter(
            (user) =>
              user.sheetTitle !== "Template" && user.sheetTitle !== "Test"
          )
          .map((user) => (
            <li key={user.sheetTitle}>
              <a
                onClick={() => {
                  mixpanel.track(
                    `User clicked to view the storefront of ${user.username}`
                  );
                }}
                href={`/${user.sheetTitle.toLowerCase()}`}
              >
                {user.username}
              </a>
            </li>
          ))}
        <li>
          <a
            onClick={() => {
              mixpanel.track(
                "User clicked to view the storefront of Natasha Giftos"
              );
            }}
            href="/natasha_g"
          >
            Natasha Giftos' Youtique
          </a>
        </li>
      </ul> */}

      <Popup open={popupOpen}>
        <form
          className="subscribePopup"
          action="https://formsubmit.co/e7248ca40be82abccf880ea8512e0456"
          method="POST"
          onSubmit={handleSubscribe}
        >
          <input
            type="email"
            className="email"
            name="email"
            placeholder="Email"
            required
          />
          <input type="hidden" name="_subject" value="Youtique Signup" />
          <input type="hidden" name="_next" value={window.location.href} />
          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="_template" value="box" />
          <Button type="submit">Submit</Button>
        </form>
      </Popup>

      {showThankYouPopup && (
        <div className="thank-you-popup">
          You're in! Look out for an email when we go live!
        </div>
      )}
    </div>
  );
};

export default LandingPage;
