import React from "react";
import "./ShopItem.css";

function ShopItem({ imageSrc, itemName, itemPrice }) {
  return (
    <div className="shop-item">
      <img className="shop-item-image" src={imageSrc} alt={itemName} />
      <div className="shop-item-name">{itemName}</div>
      <div className="shop-item-price">{itemPrice}</div>
    </div>
  );
}

export default ShopItem;
