import "./App.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Storefront from "./pages/Storefront";
import axios from "axios";
import { SERVER_URL } from "./constants";
import { user } from "fontawesome";

async function fetchData() {
  try {
    const requestUrl = `${SERVER_URL}/api/product/sheet_titles_and_usernames`;
    const response = await axios.get(requestUrl);

    if (response) {
      const sheetTitles = response.data.map((entry) => ({
        sheetTitle: entry.sheetTitle,
        username: entry.username,
      }));
      return sheetTitles;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  return null;
}

function App() {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    async function fetchDataAndSetUserData() {
      const data = await fetchData();
      // console.log(data);
      setUserData(data);
    }

    fetchDataAndSetUserData();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {userData.map((user) => (
          <Route
            key={user.sheetTitle}
            path={`/${user.sheetTitle}`}
            element={<Storefront />}
          />
        ))}
        <Route path="/" element={<LandingPage userData={userData} />} />
        <Route path="*" element={<LandingPage userData={userData} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
