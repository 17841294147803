import "./Button.css";
function Button(props) {
  let styling = props.style ? props.style : {};
  return (
    <button
      className={props.className + " button"}
      style={{
        ...styling,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export { Button };
