import React, { useEffect, useState, useRef } from "react";
import ShopItem from "../components/ShopItem/ShopItem";
import LogoHeader from "../components/LogoHeader/LogoHeader";
import axios from "axios";
import cheerio from "cheerio";
import SubscribeButton from "../components/SubscribeButton/SubscribeButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "../components/Popup/Popup";
import { Button } from "../components/Button/Button";
import { SERVER_URL } from "../constants";
import { QRCode } from "react-qr-code";
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: true,
});

// Add the bot detection code
const userAgentBotTest = navigator.userAgent;

if (/(Mozilla\/4.0)/i.test(userAgentBotTest)) {
  mixpanel.register({ $ignore: true });
}

function Storefront() {
  const [username, setUsername] = useState("");
  const [description, setDescription] = useState("");
  const [products, setProducts] = useState([]);
  const [popupOpen, setPopupState] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);

  // const handleSubscribe = () => {
  //   mixpanel.track("User subscribed");
  //   setPopupState(false);

  //   setShowThankYouPopup(true);
  //   setTimeout(() => {
  //     setShowThankYouPopup(false);
  //     window.location.reload();
  //   }, 2500);
  // };

  useEffect(() => {
    const fullPathname = window.location.pathname;
    const pathSegments = fullPathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    mixpanel.track_pageview({ page: `${lastSegment} Storefront` });

    async function fetchProductData() {
      try {
        const requestUrl = `${SERVER_URL}/api/product/storefront/${lastSegment}`;
        const response = await axios.get(requestUrl);

        setUsername(response.data.username);
        setDescription(response.data.description);
        setProducts(response.data.allProductDetails);
        return response.data.allProductDetails;
      } catch (error) {
        console.error("Error:", error);
      }
    }
    fetchProductData();
  }, []);

  return (
    <div className="App">
      <a href="/">
        <LogoHeader />
      </a>
      <div className="shop-name">{username}</div>
      <img
        src="qr-icon.png"
        className="small-qr"
        onClick={function () {
          let qr = document.getElementsByClassName("big-qr")[0];
          if (!qr.classList.contains("open")) {
            qr.classList.add("open");
          }

          const fullPathname = window.location.pathname;
          const pathSegments = fullPathname.split("/");
          const lastSegment = pathSegments[pathSegments.length - 1];

          mixpanel.track("Storefront QR code expanded", {
            "Storefront creator": lastSegment,
          });
        }}
        alt="qr code icon"
      />
      <div
        className="big-qr"
        onClick={function () {
          let qr = document.getElementsByClassName("big-qr")[0];
          if (qr.classList.contains("open")) {
            qr.classList.remove("open");
          }
        }}
      >
        <QRCode value={window.location.href}></QRCode>
      </div>
      <div className="shop-description">{description}</div>
      {/* <SubscribeButton
        className="subscribe-button"
        onClick={() => {
          if (popupOpen === false) setPopupState(1);
          else setPopupState((popupOpen + 1) % 3);

          mixpanel.track(
            "User clicked to subscribe for email updates on the storefront page"
          );
        }}
      >
        <FontAwesomeIcon icon={faBell} />
        Subscribe
      </SubscribeButton> */}
      <div className="shop">
        {[
          ...Object.values(
            products.filter((subarray) => subarray.isPinned === "TRUE")
          ),
        ].map((product) => (
          <div key={product.url} className="product-item">
            <div className="pin-icon">
              <FontAwesomeIcon icon={faThumbtack} className="thumbtack" />
            </div>

            <a
              onClick={() => {
                const fullPathname = window.location.pathname;
                const pathSegments = fullPathname.split("/");
                const lastSegment = pathSegments[pathSegments.length - 1];

                mixpanel.track("Product clicked on", {
                  Storefront: lastSegment,
                  Product: product.name,
                });
              }}
              href={product.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ShopItem
                productURL={product.url}
                imageSrc={product.imageSrc}
                itemName={product.name}
                itemPrice={product.price}
              />
            </a>
          </div>
        ))}

        {[
          ...Object.values(
            products.filter((subarray) => subarray.isPinned === "FALSE")
          ),
        ].map((product) => (
          <a
            onClick={() => {
              const fullPathname = window.location.pathname;
              const pathSegments = fullPathname.split("/");
              const lastSegment = pathSegments[pathSegments.length - 1];

              mixpanel.track("Product clicked on", {
                Storefront: lastSegment,
                Product: product.name,
              });
            }}
            key={product.url}
            href={product.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ShopItem
              productURL={product.url}
              imageSrc={product.imageSrc}
              itemName={product.name}
              itemPrice={product.price}
            />
          </a>
        ))}
      </div>

      {/* <Popup open={popupOpen}>
        <form
          className="subscribePopup"
          action="https://formsubmit.co/2084898840261f266fb812389f34b26f"
          method="POST"
          onSubmit={handleSubscribe}
        >
          <label>
            <h4>Subscribe for Youtique email updates</h4>
          </label>
          <input
            type="email"
            className="email"
            name="email"
            placeholder="Email"
            required
          />
          <input
            type="hidden"
            name="_subject"
            value="Youtique Submission to Subscribe"
          />
          <input type="hidden" name="_next" value={window.location.href} />
          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="_template" value="box" />
          <Button type="submit">Subscribe</Button>
        </form>
      </Popup>

      {showThankYouPopup && (
        <div className="thank-you-popup">
          Thank you for your interest - you will be notified soon!
        </div>
      )} */}
    </div>
  );
}

export default Storefront;
