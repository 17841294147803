import React, { useEffect, useState, useRef } from "react";

function LogoHeader() {
  const [scrolled, setScrolled] = useState(false);
  const logoRef = useRef(null);

  useEffect(() => {
    const logo = logoRef.current;
    const h = logo.getBoundingClientRect().height;
    document.body.style.marginTop = h + "px";

    const handleScroll = () => {
      if (document.documentElement.scrollTop > h) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`logo ${scrolled ? "scrolled" : ""}`} ref={logoRef}>
      YOUTIQUE
    </div>
  );
}

export default LogoHeader;
