import "./Popup.css";
import React, { useState, useEffect } from "react";

function Popup(props) {
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    if (props.open !== false) setOpen(true);
  }, [props.open]);

  function closePopup(e) {
    if (e.target !== e.currentTarget) return;
    setOpen(false);
  }
  return (
    <div
      className="popup-bg"
      style={{ display: open ? "block" : "none" }}
      onMouseDown={closePopup}
    >
      <div className="popup">
        <div className="popup-close" onClick={closePopup}>
          x
        </div>
        {props.children}
      </div>
    </div>
  );
}

export { Popup };
